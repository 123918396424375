// meetingSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for joining the meeting
export const checkuser = createAsyncThunk(
    'user/checkUser',
    async ({ userId }, { rejectWithValue }) => {
        
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API}zenstudy/api/user/getUserVerify/${userId}`,
            );

            if (response.status !== 200) {
                throw new Error(`Network response was not ok. Status code: ${response.status}`);
            }

            const data = response.data.userdetail;
           return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const checkUserSlice = createSlice({
    name: 'checkUser',
    initialState: {
        loading: false,
        error: null,
        userInfo: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkuser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(checkuser.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
            })
            .addCase(checkuser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'An error occurred';
            });
    },
});

export default checkUserSlice.reducer;
