import { combineReducers } from '@reduxjs/toolkit';
import meetingReducer from '../live/store'
import UserInfo from '../home/store';

// Combine your reducers into a single root reducer
const rootReducer = combineReducers({
    meeting: meetingReducer,
    user : UserInfo,
});

export default rootReducer;
