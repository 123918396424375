// meetingSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for joining the meeting
export const joinMeeting = createAsyncThunk(
    'meeting/joinMeeting',
    async ({ meetingNumber, userId }, { rejectWithValue }) => {
        console.log('MeetingData: ', meetingNumber, userId)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}zenstudy/api/meeting/join`,
                { id: meetingNumber }
            );

            if (response.status === 204) {
                console.log("No meeting found for this meeting number.");
                return rejectWithValue("No meeting found.");
            }

            if (response.status !== 200) {
                throw new Error(`Network response was not ok. Status code: ${response.status}`);
            }

            const data = response.data;
            if (data.meetingData) {
                const jdata = data.meetingData;

                // Add participant to the meeting
                const participantResponse = await axios.post(
                    `${process.env.REACT_APP_API}zenstudy/api/meeting/addParticipant`,
                    { meetingId: jdata._id, participantsuser: userId }
                );

                if (participantResponse.status !== 200) {
                    throw new Error(`Failed to add participant. Status code: ${participantResponse.status}`);
                }

                const participant = participantResponse.data;
                console.log("Participant", participant.participantName);

                // Pass meeting and participant data to payload
                return {
                    signature: jdata.signature,
                    meetingNumber: jdata.meetingNumber,
                    password: jdata.password,
                    participantName: participant.participantName,
                };
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const meetingSlice = createSlice({
    name: 'meeting',
    initialState: {
        loading: false,
        error: null,
        meetingInfo: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(joinMeeting.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(joinMeeting.fulfilled, (state, action) => {
                state.loading = false;
                state.meetingInfo = action.payload;
            })
            .addCase(joinMeeting.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'An error occurred';
            });
    },
});

export default meetingSlice.reducer;
