import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomeIndex from './home';
import LiveClasses from './live/live_classes';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeIndex />,
  },
  {
    path: "/live-classes",
    element: <LiveClasses />,
  },

]);

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
