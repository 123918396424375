import React, { useEffect } from "react";
import { ZoomMtg } from "@zoom/meetingsdk";
import { useDispatch, useSelector } from "react-redux";
import { joinMeeting } from "./store";
import { useLocation } from "react-router-dom";
import "./style.css";

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

const LiveClasses = () => {
  const dispatch = useDispatch();
  const { meetingInfo } = useSelector((state) => state.meeting);

  const location = useLocation();
  const { key, user } = location.state || "";


  useEffect(() => {
    if (key && user) {
      console.log("fghj");
      dispatch(joinMeeting({ meetingNumber: key, userId: user }));
    }
  }, [key, user, dispatch]);
  // Trigger meeting start when meetingInfo is available
  useEffect(() => {
    if (meetingInfo) {
      startMeeting(
        meetingInfo.signature,
        meetingInfo.meetingNumber,
        meetingInfo.password,
        meetingInfo.participantName
      );
    }
  }, [meetingInfo]);

  const startMeeting = (signature, meetingNumber, password, username) => {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: "https://zenstudy.in/",
      patchJsMedia: true,
      leaveOnPageUnload: true,
      success: () => {
        ZoomMtg.join({
          signature,
          sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
          meetingNumber,
          passWord: password,
          userName: username,
          userEmail: "", // Optional: add user email if available
          success: (success) =>
            console.log("Meeting joined successfully", success),
          error: (error) => console.log("Error joining meeting", error),
        });
      },
      error: (error) => console.log("Error initializing meeting", error),
    });
  };

  return (
    <div className="flex flex-col justify-center items-center  h-screen">
    <div className="flex items-center justify-center h-screen gap-1">
            <div className="animate-spin rounded-full h-28 w-28 border-b-2 border-gray-900"></div>
            <div className="text-4xl font-bold animate-pulse">ZenStudy.</div>
        </div>
      <div id="zmmtg-root"></div>
    </div>
  );
};

export default LiveClasses;
