import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkuser } from "./store";

const HomeIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({ key: "", user: "" });
  const dispatch = useDispatch();
  const { loading, error, userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const key = params.get("key");
    const user = params.get("user");
    setQueryParams({ key, user });

    if (user) {
      dispatch(checkuser({ userId: user }));
    }
  }, [location, dispatch]);

  useEffect(() => {
    const { key, user } = queryParams;

    if (!loading && (userInfo || error)) {
      if (userInfo && userInfo._id) {
        // console.log("Navigating to live classes");
        navigate("/live-classes", { state: { key, user } });
      } else {
        console.log("Error or no userInfo:", error);
        window.location.href = "https://zenstudy.in";
      }
    }
  }, [navigate, userInfo, error, queryParams, loading]);




  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center space-y-8">
        {/* Loading Spinner */}
        {loading && (
          <div className="flex items-center justify-center">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
        <span className="px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          Please wait...
        </span>
      </div>
    </div>
  );
};

export default HomeIndex;
